import React from "react";
import ReactGA from 'react-ga4';

ReactGA.initialize("G-ERTZBFJKYL");

export class GAListener extends React.Component {
  componentDidMount() {
    if (this.props.history) {
      this.sendPageView(this.props.history.location);
      this.props.history.listen(this.sendPageView);
    }
    else {
      this.sendPageView(this.props.location)
    }
  }
  sendPageView(location) {
    ReactGA.set({ page: location.pathname });
    ReactGA.send('pageview');
  }
  render() {
    return this.props.children;
  }
}
