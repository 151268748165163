import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';

import config from '../../config';

const defaultTitle = config.title;
const defaultDescription = config.desc;
const defaultImage = '';

class Page extends Component {
  getMetaTags(
    {
      title,
      description,
      image,
      contentType,
      noCrawl,
      published,
      updated,
      category,
      tags,
      site_name
    },
    pathname
  ) {
    const theTitle = title || defaultTitle;
    const theDescription = description || defaultDescription;
    const theImage = image ? `${config.canonicalBaseUrl}${image}` : defaultImage;

    const metaTags = [
      { itemprop: 'name', content: theTitle },
      { itemprop: 'description', content: theDescription },
      { itemprop: 'image', content: theImage },
      { name: 'description', content: theDescription },
      { property: 'og:title', content: theTitle },
      { property: 'og:type', content: contentType || 'website' },
      { property: 'og:url', content: config.canonicalBaseUrl + pathname },
      { property: 'og:image', content: theImage },
      { property: 'og:description', content: theDescription },
      { property: 'og:site_name', content: site_name || defaultTitle },
    ];

    if (noCrawl) {
      metaTags.push({ name: 'robots', content: 'noindex, nofollow' });
    }

    if (published) {
      metaTags.push({ name: 'article:published_time', content: published });
    }
    if (updated) {
      metaTags.push({ name: 'article:modified_time', content: updated });
    }
    if (category) {
      metaTags.push({ name: 'article:section', content: category });
    }
    if (tags) {
      metaTags.push({ name: 'article:tag', content: tags });
    }

    return metaTags;
  }

  render() {
    const { children, id, ...rest } = this.props;

    const canonicalUrl = this.props.canonicalUri
      ? {
        rel: 'canonical',
        href: config.canonicalBaseUrl + this.props.canonicalUri
      }
      : null;

    return (
      <div id={id}>
        <Helmet
          htmlAttributes={{
            lang: 'en',
            itemscope: undefined,
            itemtype: `http://schema.org/${rest.schema || 'WebPage'}`
          }}
          title={
            rest.title || defaultTitle
          }
          link={
            canonicalUrl? [canonicalUrl] : []
          }
          meta={this.getMetaTags(rest, this.props.location.pathname)}
        />
        {children}
        
        {id !== 'main' && 
          <footer className="fixed-bottom">
              <div className="col small p-3">
                Tangocat.net &copy; 2019 
              </div>
          </footer>
        }
      </div>
    );
  }
}

export default withRouter(Page);
