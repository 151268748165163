import React from "react";
import { Element } from "./Element";
import { displayDateRange }  from '../../../helpers/date';
import { typograpgize as typograph } from '../../../helpers/text';
import { ReactComponent as LinkIcon } from '../../assets/externalLinkIcon.svg';
import { ReactComponent as LocationButtonIcon } from '../../assets/locationButtonIcon.svg';

import config from "../../../config";
import { isIE, isFirefox, isOpera } from 'react-device-detect';

function getEventLinkHref(event) {
    const linkPing = 
      "/go/" +
      encodeURIComponent(event.title).replace(/%20/g, "+") +
      "/" +
      event.id;

    if (config.isServer || (isIE || isOpera || isFirefox))
      return {
        href: linkPing
      }
    else
      return {
        href: event.url,
        ping: linkPing,
      }
  }

const Event = React.forwardRef(({ sequentialId, event, selectedEventId, onClick }, ref) => {
    return <Element id={sequentialId} isSelected={selectedEventId === event.id} ref={ref}>

        {(event.isf && event.fi)? <img src={event.fi} className="img-fluid" alt={event.title} /> : ''}
                <p className="mb-1" dangerouslySetInnerHTML={{ __html: typograph(event.title) }} />
                <p className="small mb-1" dangerouslySetInnerHTML={{ __html: displayDateRange(event.startDate, event.endDate) }}/>
                <p className="small text-muted" dangerouslySetInnerHTML={{ __html: typograph(event.note) }} />
            
                <div className="row no-gutters">
                    <div className="col overflow-hidden flex-nowrap display-table-cell pr-2">
                    <button 
                        className="btn btn-tc-location"
                        onClick={onClick}
                    >
                        <span className="svg">
                        <LocationButtonIcon /> 
                        </span>
                        {event.l}
                    </button>
                    </div>
                    <div className="col col-auto">
                    <a
                        className="btn btn-tc-link"
                        {...getEventLinkHref(event)}
                        rel="nofollow"
                        >
                        <span className="svg">
                            <LinkIcon />
                        </span>
                        Website
                        </a>
                    </div>
                </div>
    </Element>
})

export {
    Event
}