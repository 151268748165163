import React from "react";
import Page from "../components/Page";

const arr = [
  {
    image: '/team/o.zhuk.jpg',
    name: 'Olga Zhuk',
  },
  {
    image: '/team/a.churkin.jpg',
    name: 'Alexey Churkin',
  },
];

export default () => (
  <Page id="page" title="About Tangocat.net">

    <div className="container mt-5">
      <div className="row pt-5 d-flex justify-content-center">
        <div className="col text-center">
          <p className="lead">
            Improvements? Suggestions? We’ll be happy to hear from you:
          </p>
          <p className="lead">
            <a href="mailto:hello@tangocat.net">hello@tangocat.net</a>
          </p>
        </div>
      </div>

      <div className="row pt-5">
        <div className="col text-center">
          <p className="h3">
            The team behind Tangocat:
          </p>
        </div>
      </div>

      <div className="row pt-4 d-flex justify-content-around">
          {arr.map((element, key) => (
            <div className="col-sm-4 col-md-3" key={key}>
              <div className="mb-4 text-center">
                <div className="mx-5">
                  <img src={element.image} className="rounded-circle img-fluid" alt={element.name} />
                </div>
                <div className="card-body -p-3 text-center">
                  <p className="lead">{element.name}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  </Page>
);
