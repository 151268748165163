import React from 'react';
import Page from '../components/Page';
import SubscribeForm from '../components/Forms/SubscribeForm';

import { ReactComponent as AnalyticsTeaserImage } from '../assets/analyticsTeaser.svg';

class AnalyticsRoute extends React.Component {
  componentDidMount() {
    console.log("AnalyticsRoute.componentDidMount");
  }

  componentWillUnmount() {
    console.log("AnalyticsRoute.unmount")
  }

  render() {
    return (
      <Page
        id="page"
        title="Analytics (coming soon!)"
      >
        <div className="container mt-5">
          <div className="row -pt-5 d-flex justify-content-center">
            <div className="col-sm-10 col-md-6">
              <div className="w-100 p-5">
                <AnalyticsTeaserImage className="img-fluid" />
              </div>
              <div>
                <p>
                  For four years we at Tangocat have tracked almost 3,000 tango festivals and marathons, including the vast majority of the most important and biggest events. The sample size is big enough to get a reliable analytics about the popularity of Argentinian tango worldwide.
                </p>
                <p>
                  On this webpage we’re going to visualize the data to show where and how people gather to dance tango. Now the page is in progress, the release is going to be in April 2019. You can leave you email and we’ll inform you when it’s ready.  
                </p>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-sm-10 col-md-6 text-center">
              <SubscribeForm/>
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

export default AnalyticsRoute;