import React from 'react';
import { Motion, spring } from 'react-motion';
import './ClusterMarker.sass';

const defaultScales = {
  initial: 0.6,
  default: 1,
  hovered: 1.15
}

const springOptions = { 
  stiffness: 320,
  damping: 7,
  precision:  0.001
}

// props: hovered, onClick, initialAnimation
class ClusterMarker extends React.Component {

  getScales() {
    return this.props.scales || defaultScales
  }

  render() {
    // console.log(this.props.hovered)

    const motionStyle = {
      scale: spring(
        this.props.hovered? this.getScales().hovered : this.getScales().default,
        springOptions
      )
    }

    return (
      <Motion
        defaultStyle={{
          scale: this.props.initialAnimation 
                  ? this.getScales().initial 
                  : this.getScales().default
        }}
        style={motionStyle}
      >
      {
        ({ scale }) => (
          <div
            className={this.props.className || 'cluster-marker'}
            style={{
              transform: `translate3D(0,0,0) scale(${scale}, ${scale})`,
            }}
            onClick={this.props.onClick}
          >
            {this.props.children
              ? <div className="text">{this.props.children}</div>
              : null  
            }
          </div>
        )
      }
      </Motion>
    )
  }
}

export default ClusterMarker;