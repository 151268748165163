import React from 'react';
import ClusterMarker from './ClusterMarker';

import './SimpleMarker.sass';

const defaultScales = {
  initial: 0.3,
  default: 0.6,
  hovered: 0.7
}

// props: hovered, onClick, initialAnimation, lat, lng
class SimpleMarker extends React.Component {
  constructor(props) {
    super(props);

    let className = 'simple-marker'
    if (this.props.events[0].isf) {
      // className += ' featured' + (Math.floor(Math.random() * 7) + 1);
      className += ' featured'
    }

    this.state = {
      className
    }
  }

  render() {
    return (
      <ClusterMarker {...this.props} scales={defaultScales} className={this.state.className} />
    )
  }
}


export default SimpleMarker;
