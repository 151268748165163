

class I18n {

    static months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

      static shortMonths = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];

      static getShortMonthNameByDate(date) {
          if (typeof date === 'object') {
              date = date.getMonth()
          }
          else date--;

          return I18n.shortMonths[date]
      }

      static getMonthNameByDate(date) {
          if (typeof date === 'object') {
              date = date.getMonth()
          }
          else date--;

          return I18n.months[date]
      }

}

export default I18n;
