import React from "react";

import EventList from "./EventList";

class ListRow extends React.Component {

  render() {
    return (
      <div className='row tc-row-content'>
        <main role="main" className='col-md-4 offset-md-8 my-3'>
          <EventList
            {...this.props}
          />
        </main>
      </div>
    );
  }
}

export default ListRow;
