import React from "react";
import { connect } from "react-redux";
import { frontloadConnect } from 'react-frontload';

import Page from "../components/Page";
import Map, { mapLimits } from "../components/Map/Map";
import ListRow from "../components/ListRow/ListRow";

import StickyNavStripe from "../components/StickyNavStripe/StickyNavStripe";
import I18n from "../../modules/i18n";
import { isNull } from "util";

const frontload = async props => {
  if (props.events.length <= 0)
    await props.requesters.requestEvents()
}

class MapRoute extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bounds: null,
      zoom: null,
      center: null,

      // массив идентификаторов событий из кластера на который кликнули
      // его нужно передать в EventList для отображения модальным списком
      // Обновляется через onClusterMarkerClick
      // Обнуляется через onClusterEventsCloseCallback
      selectedEventIds: null,

      // подксролл
      scrollToEventId: null,

    };
  }

  componentDidMount() {
    console.log("MapRoute.componentDidMount");
  }

  componentWillUnmount() {
    console.log("MapRoute.unmount")
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('MapRoute.componentDidUpdate')

    // после рендеринга нужно сбросить scrollToEventId чтобы не было бесконечных подскроллов
    // См. shouldComponentUpdate() который блокирует повторный ре-рендеринг в целях оптимизации
    if (this.state.scrollToEventId !== null)
      this.setState({scrollToEventId: null})
  }

  shouldComponentUpdate(nextProps, nextState) {
    // сбрасываем подскролл без повторного рендеринга
    if (
          this.state.scrollToEventId !== nextState.scrollToEventId &&
          nextState.scrollToEventId === null
    )
      return false;
    return true;
  }

  _onMapChanged = ({ center, zoom, bounds }) => {
    console.log('MapRoute._onMapChanged', { center, zoom, bounds } )

    if (
        this.state.center !== center || 
        this.state.zoom !== zoom ||
        this.state.bounds !== bounds
    )
      this.setState({ center, zoom, bounds });

    // this.props.history.push({
    //   hash: "p=" + [center.lat, center.lng].join(",")
    // });
  };

  _onLocationEscalate = ({ center }) => {
    console.log('MapRoute._onLocationEscalate', { center })

    if (
        this.state.center !== center
        || this.state.zoom < mapLimits.optimalZoom
    )
      this.setState({ center, zoom: mapLimits.optimalZoom });
  }

  onSimpleMarkerClick = (event) => {
    this.setState({
      scrollToEventId: [event.id],

      // обнуляем группировку
      selectedEventIds: null,
    })
  }

  onClusterMarkerClick = (events) => {
    const eventIds = events.map(ev => ev.id)
    
    this.setState({
      // устанавливаем группировку
      // если уже установлена 
      // - то сбрасываем, если клик по тому же самому маркеру
      // - переключаем, если клик по новому маркеру
      selectedEventIds: 
        this.state.selectedEventIds !== null
          ? (eventIds.join(',') === this.state.selectedEventIds.join(',')? null : eventIds)
          : eventIds,
    })
  }

  onClusterEventsCloseCallback = () => {
    this.setState({
      selectedEventIds: null,
    })
  }

  isInsideBounds(event) {
    // console.log(event.coords, !isNaN(event.coords.lat), {event})
    return (
      !isNaN(event.coords.lat) && 
      !isNaN(event.coords.lng) && (
        !this.state.bounds || // если регион еще не загружен - показываем все события
        (
          event.coords.lat > this.state.bounds.se.lat &&
          event.coords.lat < this.state.bounds.ne.lat &&
          event.coords.lng > this.state.bounds.nw.lng &&
          event.coords.lng < this.state.bounds.ne.lng)
      )
    )
  }

  isFeatured(event) {
    return event.isf === true;
  }

  getEvents() {
    // .onMap, .onClickedCluster
    return this.props.events.map(event => {
      event.onMap = this.isInsideBounds(event);
      event.onClickedCluster = 
        this.state.selectedEventIds &&
        this.state.selectedEventIds.length > 0 &&
        this.state.selectedEventIds.includes(event.id);
      return event;
    })
  }

  getMetadata() {
    const events = this.getEvents();

    let metaData = {
      site_name: 'Tangocat.net',
      image: '/og.png',

      // нужно для S-E-O - макс. дата редактирования события будет указана
      // как дата изменения
      updated: this.props.updated,
    };
    const eventsNumberRounded = Math.round(this.props.events.length / 100) * 100;

    if (this.props.date.year === null) {
      metaData.title = 'Worldwide Tango Festivals and Marathons Catalogue on Tangocat';  
      metaData.description = `The biggest database of tango festivals and marathons: ${eventsNumberRounded} events and counting ⦿ Pick a place for your next tango holiday!`
    }
    else {
      const eventsNumber = events.length;
      const counting = (
        this.props.date.year >= new Date().getFullYear() &&
        (!this.props.date.month || this.props.date.month >= (new Date().getMonth() + 1))
      );
      const _date = (this.props.date.month? I18n.getMonthNameByDate(this.props.date.month) + ' ': '') + this.props.date.year;

      if (eventsNumber) {
        metaData.title = `${_date} on Tangocat: ${eventsNumber} tango festivals and marathons`
        // metaData.description = `Tangocat.net 💃 ${eventsNumber} tango events in ${_date} ${counting? 'and counting' : ''} ⦿ Explore ${eventsNumberRounded}+ tango festivals and marathons worldwide ⦿ Pick a place for your next tango holiday`
        metaData.description = `The biggest database of tango festivals and marathons: ${eventsNumber} events and counting ⦿ Pick a place for your next tango holiday!`
      }
      else {
        metaData.title = `Tango festivals and marathons ${_date} on Tangocat`
        metaData.description = `Tangocat.net 💃 Tango events in ${_date} ${counting? 'and counting' : ''} ⦿ Explore ${eventsNumberRounded}+ tango festivals and marathons worldwide ⦿ Pick a place for your next tango holiday`
        }
    }

    metaData.canonicalUri = 
      "/" + 
      (this.props.location.pathname === '/'
        ? '' 
        : (
            (this.props.date.year? `${this.props.date.year}/` : '') +
            (this.props.date.month? `${this.props.date.month}/` : '')
        )
      )

    return metaData
  }

  render() {
    console.log("MapRoute.render", this.state);

    const events = this.getEvents()
    const clusteredEvents = events.filter(event => event.onClickedCluster)

    return (
      <Page 
          id="main"
          {...this.getMetadata()}
      >
        <ListRow
          onLocationEscalate={this._onLocationEscalate}
          events={events}

          clusteredEvents={clusteredEvents}
          hasClusteredEvents={clusteredEvents.length > 0}
          onClusteredEventListClose={clusteredEvents.length > 0 ? this.onClusterEventsCloseCallback : null} 

          date={this.props.date}
          scrollToEventId={this.state.scrollToEventId}
        />
        
        <StickyNavStripe selected={this.props.date}/>

        <Map 
          onChange={this._onMapChanged} 
          onSimpleMarkerClick={this.onSimpleMarkerClick}
          onClusterMarkerClick={this.onClusterMarkerClick}

          center={this.state.center} 
          zoom={this.state.zoom}
          events={events.filter(event => event.onMap)} 

          // передаем текущий стейт карты имеено из MapRoute 
          // т.к. он главный получатель этих данных (onChange)
          mapState={{bounds: this.state.bounds, zoom: this.state.zoom}} 
        />
      </Page>
    );
  }
}

const isActualEvent = ({year, month}) => event => {
  if (isNull(month) && isNull(year))
    return true;

  if (event.startDate.getFullYear() === year) {
    if (isNull(month)) return true;
    if ((1 + event.startDate.getMonth()) === month) return true;
  }

  if (event.endDate.getFullYear() === year) {
    if (isNull(month)) return true;
    if ((1 + event.endDate.getMonth()) === month) return true;
  }

  return false;
}

const mapStateToProps = ({store}, {date}) => ({
  updated: store.updated,

  events: store.events
    .map(event => {

      event.coords = {
        lat: parseFloat(event.p[0]),
        lng: parseFloat(event.p[1])
      };

      event.startDate = new Date(event.ds);
      event.endDate = new Date(event.de);
      return event;
    })
    .filter(isActualEvent(date)),
})

export default connect(mapStateToProps)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(MapRoute)
);
