
const isServer = !!!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const isDev =  process.env.NODE_ENV !== 'production';
const isVerbose = isDev && false;

export default {
  baseUrl: isDev ? "http://localhost:3000" : "https://tangocat.net",

  canonicalBaseUrl: "https://tangocat.net",

  isDev, isVerbose, isServer,

  sentry: {
    dsn: "https://002c312580e549ccaa6e6db95843b921@sentry.io/1462088",
    release: process.env.REACT_APP_BUILD_REVISION,
  }
};
