import React from "react";

const Element = React.forwardRef(({ id, isSelected, children }, ref) => {
    return (
        <li 
            key={id} 
            ref={ref}
            className={
              "p-3" +
              (isSelected ? ' selected' : '') + 
              (id % 2 ? ' odd' : ' even')
            }
        >
            {children}
        </li>
    )
})

export {
    Element
}