import React from "react";
import { withRouter } from "react-router";

import GoogleMapReact from "google-map-react";

import { isMobile } from "react-device-detect";
import config from '../../../config'

import "./Map.scss";

/**
 * Карта в качестве фона (background). За основу взято решение 
 * https://www.labnol.org/internet/embed-google-maps-background/28457/
 * 
 * Кластер основан на имплементации https://github.com/istarkov/google-map-clustering-example
 * с переписанным исходным кодом (отказался от recompose, вернул код к использованию 
 * нативного Supercluster) и обновлению зависимостей
 * 
 */

import Supercluster from 'supercluster';
import ClusterMarker from "./Markers/ClusterMarker";
import SimpleMarker from "./Markers/SimpleMarker";


export const mapLimits = {
  minZoom: 3, // ??? почему-то не получается сделать меньше, видимо ограничение 
  zoom: 5, // Landmass/continent, see https://developers.google.com/maps/documentation/javascript/tutorial#zoom-levels
  optimalZoom: 8,
  maxZoom: 10, // City, see https://developers.google.com/maps/documentation/javascript/tutorial#zoom-levels
}

const defaultProps = {
  center: {
    lat: 50.097221,
    lng: 14.377972
  },
  ...mapLimits,
  hoverDistance: 30,
};

const createMapOptions = map => {
  return {
    scrollwheel: false,
    mapTypeControl: false,
    draggable: true,
    disableDefaultUI: isMobile,
    mapTypeControlOptions: {
      position: map.ControlPosition.BOTTOM_LEFT
    },
    zoomControlOptions: {
      position: map.ControlPosition.LEFT_CENTER, // as long as this is not set it works
      style: map.ZoomControlStyle.SMALL
    },
    fullscreenControl: false,
    // minZoom: defaultProps.minZoom,
    maxZoom: defaultProps.maxZoom,
  };
};

//
// props: onChange, center, events, mapState
//
class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredMarkerId: false,
    }
  }

  _onMapChange = ({ center, zoom, marginBounds }) => {
    this.props.onChange({ center, zoom, bounds: marginBounds });
  };

  onChildMouseEnter = (hoverKey, {id}) => {
    this.setState({hoveredMarkerId: id})
  }

  onChildMouseLeave = (hoverKey, {id}) => {
    if (id === this.state.hoveredMarkerId)
      this.setState({hoveredMarkerId: null})
  }

  onChildClick = (_, {events}) => {
    if (events.length === 1)
      this.props.onSimpleMarkerClick(events[0])
    else if (events.length > 1)
      this.props.onClusterMarkerClick(events)
  }

  // правильнее было бы вытащить это код в отдельный компонент, например Markers:
  // <Markers events={this.props.events} mapState={this.props.mapState}
  // но GoogleMapReact принимая один элемент в качестве child-а считает его как InfoWindow
  // и все вложенные маркеры перемещает на одну точку
  renderPoints() {
    // генерируем маркеры ТОЛЬКО если уже известны границы
    if (!this.props.mapState.bounds || !this.props.mapState.zoom) 
      return null;

    return this.getPoints().map(({id, events, coords: {lat, lng}}) => {
      // id содержит либо ID события, либо ID кластера. Они могут пересекаться (возможно)
      // поэтому нам надо сгенерировать новый id/key. Добавим просто объем кластера
      const length = events.length;
      const key = id = length + '@' + id;

      // пропсы для компонента
      const props = {
        key, 
        id, 
        events, 
        lat, 
        lng, 
        hovered: this.state.hoveredMarkerId === id
      };

      return (events.length === 1) 
        ? <SimpleMarker {...props} />
        : <ClusterMarker {...props}>{events.length}</ClusterMarker>
    });
  }

  getPoints() {
    // можно было бы вынести Кластер на уровень стейта (сделать derived state from props)
    // но в данном компоненте и так очевидно что изменение props меняет структуру events
    // и нужно провести рекластеризацию по новой
    const superCluster = new Supercluster({
      minZoom: defaultProps.minZoom, 
      maxZoom: defaultProps.maxZoom, 
      radius: 40, // Cluster radius, in pixels. 
      log: config.isVerbose
    });

    // загружаем события, используя формат GeoJSON
    superCluster.load(
        this.props.events.map((event) => ({
          type: "Feature",
          properties: {
            event
          },
          geometry: {
            type: "Point",
            coordinates: [event.coords.lng, event.coords.lat]
          }
        }))
    );

    // Кластеризуем на основе mapState
    const clusters = superCluster.getClusters(
      [ /*westLng*/this.props.mapState.bounds.nw.lng, 
        /*southLat*/this.props.mapState.bounds.se.lat, 
        /*eastLng*/this.props.mapState.bounds.ne.lng, 
        /*northLat*/this.props.mapState.bounds.ne.lat], 
      this.props.mapState.zoom
    );
    
    // Генерируем points - точки для карты
    return clusters.map(cluster => {
      return {
        id: cluster.properties.event ? cluster.properties.event.id : cluster.properties.cluster_id,
        coords: {
          lng: cluster.geometry.coordinates[0],
          lat: cluster.geometry.coordinates[1],
        },
        events: 
          cluster.properties.event
            // если эта точка - не кластер -
              ? [cluster.properties.event]
            // если кластер
              : superCluster.getLeaves(cluster.properties.cluster_id, Infinity).map((child) => child.properties.event),
      }
    });
  }

  render() {
    const nonMobileOpts = 
      !isMobile
        ? {onChildMouseEnter: this.onChildMouseEnter, onChildMouseLeave: this.onChildMouseLeave}
        : {}

    return (
      <div className="mapContainer">
        <div className="map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBInR8i2NaJaLH52wWO_Sjed2WGGG5wmh0"
            }}
            center={this.props.center || defaultProps.center}
            zoom={this.props.zoom || defaultProps.zoom}
            options={createMapOptions}
            onChange={this._onMapChange}
            // onGoogleApiLoaded={({map, maps}) => ()}

            // TODO: задать правильные margins чтобы получать корректные границы видимой 
            // области в географических координатах, без необходимости проверять перекрытие 
            // маркеров соседними элементами (https://stackoverflow.com/a/12067046)
            // Иными словами, правильнее использовать географические координаты чем браузерные
            margin={[/*top*/ 0, /*right TODO*/ 0, /*bottom*/ 0, /*left*/ 0]}

            hoverDistance={defaultProps.hoverDistance}
            // distanceToMouse={({ x, y }, { x: mouseX, y: mouseY }) => {
            //   return Math.sqrt((x - mouseX) * (x - mouseX) + (y - mouseY) * (y - mouseY))
            // }}
            
            {...nonMobileOpts}
            onChildClick={this.onChildClick}
          >
            {this.renderPoints()}
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

export default withRouter(Map);
