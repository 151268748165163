import React from 'react';

const States = {
    DEFAULT: 0,
    LOADING: 1,
    ERROR: 2,
    SUCCESS: 3,
    INVALID: 4,
}

class SubscribeForm extends React.Component {
    constructor(props) {
        super(props);

        this.form = React.createRef()

        this.state = {
            state: States.DEFAULT,
            email: '',
            canBeSubmitted: false,
            invalidFields: [],
        }
    }

    handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value,
        canBeSubmitted: (name === 'email'? value : this.state.email).length > 0,
      });
    }

    onSubmit = (e) => {
        e.preventDefault();

        // if (this.form.current.checkValidity() === false) {
        //     return this.setState({state:States.INVALID})
        // }

        if (!this.state.canBeSubmitted) return;

        this.setState({state: States.LOADING});

        fetch('/api/contrib/subscribe', {
            method: 'POST',
            body: JSON.stringify({
                email: this.state.email,
            })
        })
            .then((response) => {
                if (response.ok)
                    return response.json()
                throw new Error("Network error")
            })
            .then(resp => {
                // console.log('then', resp)
                if (resp.status === 'ok')
                    this.setState({
                        state: States.SUCCESS,
                        email: '',
                        canBeSubmitted: false,
                    });

                else if (resp.status === 'invalid')
                    this.setState({
                        state: States.INVALID,
                        invalidFields: resp.fields || []
                    })
                    
                else 
                    throw new Error("Error on server side")
            })
            .catch(err => {
                this.setState({state: States.ERROR})
            })
    }

    isInvalidField(fieldName) {
        return this.state.state === States.INVALID && this.state.invalidFields.includes(fieldName)
    }

    render() {
        if (this.state.state === States.SUCCESS)
            return (
                <div className="alert alert-success" role="alert">
                    We'll write you back when there is something to excite you!
                </div>
            )

        return (
            <form ref={this.form} onSubmit={this.onSubmit} noValidate>
                <fieldset disabled={this.state.state === States.LOADING? 'disabled' : ''}>

                    {this.state.state === States.ERROR &&
                        <div className="alert alert-danger" role="alert">
                            Crash-boom-bang! The server stopped responding (probably it had a little hiccup), so we cannot save your email address. Please try again or send us an email to <a href="mailto:hello@tangocat.net">hello@tangocat.net</a>.
                        </div>
                    }
                    <div className="form-group px-5 py-3">
                        <input 
                            name="email" 
                            onChange={this.handleInputChange} 
                            type="email" 
                            className={"form-control" + (this.isInvalidField('email') ? ' is-invalid': '')}  
                            id="suggestEvent_email" 
                            aria-describedby="suggestEvent_email_help" 
                            placeholder="Enter email" 
                            required 
                            value={this.state.email}
                        />
                        <small id="suggestEvent_email_help" className="form-text text-muted">We'll never share your email with anyone else. No spam, only exciting announcements!</small>
                        <div className="invalid-feedback">
                            Please provide a valid email address
                        </div>

                    </div>
                    <button className="btn btn-tc btn-md" onClick={this.onSubmit} disabled={!this.state.canBeSubmitted}>
                        {this.state.state === States.LOADING && 
                            <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
                        }
                        Subscribe
                    </button>
                </fieldset>
            </form>
        )
    }
}


export default SubscribeForm;