
import singleSpaces          from 'typographic-single-spaces';

import apostrophes           from 'typographic-apostrophes';
import quotes                from 'typographic-quotes';
import apostrophesForPlurals from 'typographic-apostrophes-for-possessive-plurals';

import endashes              from 'typographic-en-dashes';
import emdashes              from 'typographic-em-dashes';

// const processors = [
//     require("typographic-single-spaces"),

//     require("typographic-apostrophes"),
//     require("typographic-apostrophes"),
//     require("typographic-apostrophes-for-possessive-plurals"),

//     require("typographic-en-dashes"),
//     require("typographic-em-dashes"),
// ]

const _safeHtml = text => text
    .replace(/</, '&lt;')
    .replace(/>/, '&gt;')

export const typograpgize = text => _safeHtml(
    singleSpaces(
        apostrophes(
            quotes(
                apostrophesForPlurals(
                    endashes(
                        emdashes(
                            text
                        )
                    )
                )
            )
        )
    )
)