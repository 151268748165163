// The basics
import React, { Component } from "react";
import { withRouter } from "react-router";

import "./App.scss";

import Header from "./components/Header";

import ScrollToTop from '../modules/ScrollToTop';

import { Switch, Route } from "react-router-dom";
import MapRoute from "./routes/MapRoute";
import NotFoundRoute from "./routes/NotFoundRoute";
import AboutRoute from "./routes/AboutRoute";
import AnalyticsRoute from "./routes/AnalyticsRoute";
import Newsletter from "./routes/Newsletter";

import { isUndefined } from "util";

class MapRouter extends React.Component {
  getDateFromProps(props) {
    const year = (props || this.props).match.params.year;
    const month = (props || this.props).match.params.month;

    return {
      year: !isUndefined(year)? parseInt(year) : null,
      month: (!isUndefined(year) && !isUndefined(month))? parseInt(month) : null,
    }
  }

  render() {
    const date = this.getDateFromProps()
    return <MapRoute {...this.props} {...date} date={date} />
  }
}

const myMapRoute = requesters => props => {
  return (
    <MapRouter
      requesters={requesters}
      {...props}
    />
  );
}

class App extends Component {
  render() {

    return (<>
        <Header current={this.props.location.pathname} />
        <div className="container-fluid">

          <ScrollToTop>
            <Switch>
              <Route
                exact
                path="/:year(20[1-2]\d)?/:month([1-9]|10|11|12)?"
                render={myMapRoute(this.props.requesters)}
              />
              <Route exact path="/about" component={AboutRoute} />
              <Route exact path="/analytics" component={AnalyticsRoute} />
              <Route exact path="/newsletter" component={Newsletter} />

              <Route component={NotFoundRoute} />
            </Switch>
          </ScrollToTop>

        </div>
    </>)
  }
}

export default withRouter(App);

