import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as HamburgerIcon } from '../../assets/bars-solid.svg';

import Modal from "../Modal";
import SuggestionForm from "../Forms/SuggestionForm";
import MenuList from "./StickyNavbarMenuList";

import "./StickyNavbar.scss";


// Имплементация https://stackoverflow.com/a/42540052

class StickyNavbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = { 
      // статус распахнутого меню (развернуто / схлопнуто). Нужно для работы на мобилках
      menuState: false,

      // статус модалки (развернуто / схлопнуто)
      suggestionModalState: false,
    };
  }

  toggleMenu = () => {
    this.setState(prevState => ({ menuState: !prevState.menuState }));
  }

  closeMenu = () => {
    if (this.state.menuState === true)
      this.setState({menuState: false})
  }

  toggleSuggestionModal = () => {
    this.setState(prevState => ({ 
      suggestionModalState: !prevState.suggestionModalState,
      menuState: false,
    }));
  }

  render() {
    return (<>
      <div className="fixed-top tc-stickynavbar-container d-flex align-items-center" role="navigation">
        <nav 
          className={
            "navbar navbar-tc navbar-expand-lg shadow flex-row mx-0" +
            (this.state.menuState === true ? ' manually-expanded' : '')
          }
        >
          <Link className="navbar-brand -d-flex mr-5" to="/" onClick={this.closeMenu}>
            Tangocat.net
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            onClick={this.toggleMenu}
          >
            <span className="svg">
              <HamburgerIcon style={{width: '1.5rem'}} />
            </span>
          </button>

          <div
            className={
              "navbar-collapse w-100" +
              (this.state.menuState === true ? " shadow" : " collapse")
            }
            id="navbarSupportedContent"
          >
            <MenuList
              current={this.props.current}
              className="navbar-nav mr-lg-auto"
              onClick={this.closeMenu}
            />
            <button
              className="btn btn-outline-tc my-2 my-lg-0"
              type="button"
              onClick={this.toggleSuggestionModal}
            >
              Suggest event
            </button>
          </div>
        </nav>
      </div>

      <Modal isOpen={this.state.suggestionModalState} onToggle={this.toggleSuggestionModal} title="Suggest new event">
            <SuggestionForm/>
      </Modal>
    </>);
  }
}

export default StickyNavbar;
