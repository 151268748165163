import React from 'react';
import { withRouter } from 'react-router'; 
import config from '../config';

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
      if (
            !config.isServer &&
            this.props.location.pathname !== prevProps.location.pathname
        ) {
        window.scrollTo(0, 0);
      }
    }
  
    render() {
      return this.props.children;
    }
  }
  
export default withRouter(ScrollToTop);