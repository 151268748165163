import React from 'react';
import I18n from "../../../modules/i18n";

const Part = ({
    activeType, expectedType, events, str, onClick
}) => {

    return (
        <div 
            className={
                "col text-center" +
                (activeType === expectedType? ' active' : '')
            }
            onClick={() => onClick(expectedType)}
        >
            {(activeType === expectedType? '•' : '')} 
            {activeType? <br/> : ''}
            {events(expectedType).length} events<br />
            <small>{str}</small>
        </div>
    )
}

export const EventListStickyHeader = ({
    eventListType,
    listTypes,
    onClick,
    events,
    date
}) => {

    let str = 'totally';
    if (date.year) 
        str += ` in ${date.month ? I18n.getMonthNameByDate(date.month) : ''} ${date.year}`

    return (
        <div className="row no-gutters p-0 py-2 align-items-center">
            <Part 
                activeType={eventListType} 
                expectedType={listTypes.MAPPED}
                events={events}
                str="on the map"
                onClick={onClick}/>
            <Part 
                activeType={eventListType} 
                expectedType={listTypes.COMPLETE}
                events={events}
                str={str}
                onClick={onClick}/>
        </div>
      )
}