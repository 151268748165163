const 
    SET_EVENTS = 'set_events',
    SET_ANALYTICS = 'set_stats'
;

const initialState = {
  events: [],
};

export default (state = initialState, action) => {
  switch (action.type) {

    case SET_EVENTS:
      return {
        ...state,
        events: action.payload.events,
        updated: action.payload.updated,
      };

    case SET_ANALYTICS:
      return {
          ...state,
          analytics: action.payload
      };

    default:
      return state;
  }
};

export const setEvents = dispatch => data => dispatch({
    type: SET_EVENTS,
    payload: data
  })

export const setAnalytics = dispatch => data => dispatch({
    type: SET_ANALYTICS,
    payload: data
  })