import React from 'react';

const States = {
    DEFAULT: 0,
    LOADING: 1,
    ERROR: 2,
    SUCCESS: 3,
    INVALID: 4,
}

const SPACE = ` `;
const DEFAULT_DESC = `Title:${SPACE}
Dates:${SPACE}
Country, city:${SPACE}
(optional) list of notable DJs/musicians/maestros:${SPACE}`

class SuggestionForm extends React.Component {
    constructor(props) {
        super(props);

        this.form = React.createRef()

        this.state = {
            state: States.DEFAULT,
            url: '', email: '', details: DEFAULT_DESC,
            canBeSubmitted: false,
            invalidFields: [],
        }
    }

    handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      this.setState({
        [name]: value,
        canBeSubmitted: (name === 'url'? value : this.state.url).length > 0,
      });
    }

    onSubmit = (e) => {
        e.preventDefault();

        // не хочется проверять на уровне браузера потому что проверки слишком жесткие
        // к примеру, пользователь может ввести ссылку без 'http://' что для нас не проблема
        // но браузер ругнется и не пропустит
        // if (this.form.current.checkValidity() === false) {
        //     return this.setState({state:States.INVALID})
        // }

        if (!this.state.canBeSubmitted) return;

        this.setState({state: States.LOADING});

        fetch('/api/contrib/submit', {
            method: 'POST',
            body: JSON.stringify({
                url: this.state.url,
                email: this.state.email,
                details: this.state.details
            })
        })
            .then((response) => {
                if (response.ok)
                    return response.json()
                throw new Error("Network error")
            })
            .then(resp => {
                // console.log('then', resp)
                if (resp.status === 'ok')
                    this.setState({
                        state: States.SUCCESS,
                        url: '',
                        details: '',
                        canBeSubmitted: false,
                    });

                else if (resp.status === 'invalid')
                    this.setState({
                        state: States.INVALID,
                        invalidFields: resp.fields || []
                    })

                else
                    throw new Error("Error on server side")
            })
            .catch(err => {
                console.error('catch', err)
                this.setState({state: States.ERROR})
            })
    }

    onClose = () => {
        this.setState({state: States.DEFAULT})
        this.props.onToggle();
    }

    isInvalidField(fieldName) {
        return this.state.state === States.INVALID && this.state.invalidFields.includes(fieldName)
    }

    render() {
        if (this.state.state === States.SUCCESS)
            return (
                <p>
                    We’ve got your suggestion. Thanks for making Tangocat better! We’ll reply in the shortest possible time!
                </p>
            )

        return (
            <form ref={this.form} onSubmit={this.onSubmit} noValidate>
                <fieldset disabled={this.state.state === States.LOADING? 'disabled' : ''}>

                {this.state.state === States.ERROR &&
                    <div class="alert alert-danger" role="alert">
                        Crash-boom-bang! The server stopped responding (probably it had a little hiccup), so we cannot save your suggestion. Please try again or send us an email to <a href="mailto:hello@tangocat.net">hello@tangocat.net</a>.
                    </div>
                }

                <div class="form-group">
                    <label htmlFor="suggestEvent_link">Event website</label>
                    <input
                        name="url"
                        onChange={this.handleInputChange}
                        type="url"
                        className={"form-control" + (this.isInvalidField('url') ? ' is-invalid': '')}
                        id="suggestEvent_link"
                        placeholder="http://..."
                        value={this.state.url}
                        required
                    />
                    <small id="suggestEvent_link" class="form-text text-muted">Required</small>
                    <div class="invalid-feedback">
                        Please provide a valid URL address
                    </div>
                </div>
                <div class="form-group">
                    <label htmlFor="suggestEvent_details">Event details</label>
                    <textarea name="details" onChange={this.handleInputChange} className="form-control" id="suggestEvent_details" rows="4" placeholder="Title, country and city, dates, list of maestros/DJs/musicians" value={this.state.details} />
                    <small id="suggestEvent_email_help" class="form-text text-muted">Specify event's title, a country and the city where event takes place, dates event starts and ends, and optionally a list of notable maestros/DJs/musicians.</small>
                </div>
                <div class="form-group">
                    <label htmlFor="suggestEvent_email">Your personal email</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                        <div class="input-group-text">@</div>
                        </div>
                        <input name="email" onChange={this.handleInputChange} type="email" class="form-control" id="suggestEvent_email" aria-describedby="suggestEvent_email_help" placeholder="sample@example.com" value={this.state.email} />
                    </div>
                    <small id="suggestEvent_email_help" class="form-text text-muted">Optional. We'll write you back when event being published. We'll never share your email with anyone else.</small>
                </div>

                <div class="row justify-content-center">
                    <button className="btn btn-tc btn-md" onClick={this.onSubmit} disabled={!this.state.canBeSubmitted}>
                            {this.state.state === States.LOADING &&
                                <span class="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>
                            }
                            Submit
                    </button>
                </div>
                </fieldset>
            </form>
        )
    }
}


export default SuggestionForm;