import React from "react";
import { Link } from "react-router-dom";
import { Indicator } from "./Indicator";
export const LinkList = ({ links }) => {
    let activeCount = -1;
    return (
        <>
        <ul className="list-inline m-0 d-flex align-items-center">
            {links.map((link, key) => {
                if (link.active)
                    activeCount = key;

                return (
                    <li key={key} className={"m-0 p-0" + (link.active? '  active' : '')}>
                        <Link key={key} to={link.to}>
                            {link.label}
                        </Link>
                    </li>
                )
            })}
        </ul>
        <Indicator offset={activeCount} />
        </>
    );
};
