import 'react-app-polyfill/ie9';

import React from "react";

import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import { Frontload } from 'react-frontload';
import { Router } from "react-router-dom";
import createStore from "./store";

import App from "./app/App";
import { GAListener } from "./modules/GAListener";

import datalayer from './modules/datalayer';
import fetchers from './client/fetchers';

// Create a store and get back itself and its history object
const { store, history } = createStore();

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
const Application = (
  <Provider store={store}>
    <Router history={history}>
      <GAListener history={history}>
        <Frontload noServerRender={true}>
          <App requesters={datalayer(fetchers)(store)} />
        </Frontload>
      </GAListener>
    </Router>
  </Provider>
);

const root = document.querySelector("#root");

if (root.hasChildNodes() === true) {
  hydrate(Application, root);
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}
