import React from "react";

/**
 * За основу взято решение 
 * https://benfrain.com/a-horizontal-scrolling-navigation-pattern-for-touch-and-mouse-with-moving-current-indicator/
 * http://benfrain.com/playground/scroller.html
 * 
 * TODO: dragscroll (см. _DragScroll.js)
 */

import "./Ribbon.scss";

import config from "../../../config";
import settings from './Settings';

import { LinkList } from "./LinkList";

import { ReactComponent as RibbonArrowLeft } from './assets/ribbonArrowLeft.svg';
import { ReactComponent as RibbonArrowRight } from './assets/ribbonArrowRight.svg';

const RibbonViewportOverflowType = {
  BOTH: 'both',
  LEFT: 'left',
  RIGHT: 'right',
  NONE: 'none'
};

const RibbonCSSTransitionDirection = {
  LEFT: 0,
  RIGHT: 1,
}

class Ribbon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // нужно чтобы установить нужный класс и скрыть/показать кнопки подсролла
      ribbonViewportOverflowType: RibbonViewportOverflowType.RIGHT,

      // это управляется ТОЛЬКО колбеком onTransitionEnd который срабатвыает после завершения
      // CSS Transtition вложенного элемента ribbon, нужно чтобы задать скролл
      ribbonViewportScrollLeft: 0,

      //
      // управление положением ribbon
      // 

      // это влияет на CSS transition (translateX), позволяет смещать контейнер
      // по оси Х на любую длину заданую в т.ч. rem/em (а scrollLeft только px принимает)
      ribbonCSStransitionTranslateXValue: null,

      // мьютекс на подскролл ProductNavContaints (при нажатии на кнопку left/right или на первончальный подскролл)
      ribbonCSSTransitionLock: false,

      // направление подскролла
      ribbonCSSTransitionDirection: null,
    };

    this.ribbonViewport = React.createRef();
    this.ribbon = React.createRef();
  }

  // Метод считает расстояние для подскролла при нажатии на left/right
  getOpportunisticClickScroll() {
    // определим среднюю длинну элемента
    // Они все одинаковые и известны из SCSS (см. options.HARDCODED_baseWidth) но
    // нам нужна величина строго в пикселях, поэтому мы пробуем определить ее калькуляцией:
    // делим длину контейнера на количество ссылок
    const ribbonWidth = this.ribbon.current.getBoundingClientRect().width;
    const elementWidth = (ribbonWidth / this.props.links.length);

    // подскролл
    return 1.5 * elementWidth
  }

  componentDidMount() {
    if (!config.isServer) {
      if (document.readyState === 'complete')
        this.refreshRibbonViewportOverflowType()
      else
        window.addEventListener('load', this.refreshRibbonViewportOverflowType.bind(this))
    }

    // При загрузке нам надо сделать подскролл к активной ссылке
    // Для этого нужно найти активную ссылку и сделать ribbonCSSTransition 

    // определим положение активной ссылки
    const numberOfLinks = this.props.links.length;
    for (var activeLinkPos = 0; activeLinkPos < numberOfLinks; activeLinkPos++) 
      if (this.props.links[activeLinkPos].active) 
        break;
    
    // если есть активная ссылка и она не первая, то производитм transition
    if (activeLinkPos > 0) {
      let remOffset = (activeLinkPos * settings.baseWidth)

      // делаем возврат надвое чтобы был виден предыдущий элемент (частично)
      if (activeLinkPos < (numberOfLinks - 1))
        remOffset -= settings.baseWidth / 2;

      this.setState({
        ribbonCSSTransitionLock: true,
        ribbonCSSTransitionDirection: RibbonCSSTransitionDirection.RIGHT,
        ribbonCSStransitionTranslateXValue: (-remOffset) + settings.baseUnit
      })
    }
  }

  componentDidUpdate() {
    // TODO: по какой-то причине подскролл не всегда срабатывает, поэтому надо ставить небольшой таймаут, 
    // дать время на отрисовку. И только для первого срабатывания
    
    if (!this.state.ribbonCSSTransitionLock)
        this.ribbonViewport.current.scrollLeft = this.state.ribbonViewportScrollLeft

     this.refreshRibbonViewportOverflowType()
  }

  refreshRibbonViewportOverflowType() {
    const ribbonViewportOverflowType = this.getRibbonViewportOverflowType();
    if (this.state.ribbonViewportOverflowType !== ribbonViewportOverflowType)
     this.setState({ ribbonViewportOverflowType });
  }

  // Срабатывает при скролле, нужен чтобы:
  // * обновить ribbonViewportOverflowType 
  // * сохранить текущий скролл в стетй
  ribbonViewportScrollCallback = () => {
    this.setState((prevState) => {
      let newState = {};

      const ribbonViewportOverflowType = this.getRibbonViewportOverflowType()
      if (prevState.ribbonViewportOverflowType !== ribbonViewportOverflowType)
        newState.ribbonViewportOverflowType = ribbonViewportOverflowType;

      const ribbonViewportScrollLeft = this.ribbonViewport.current.scrollLeft 
      if (prevState.ribbonViewportScrollLeft !== ribbonViewportScrollLeft) 
        newState.ribbonViewportScrollLeft = ribbonViewportScrollLeft

      return Object.keys(newState) === 0 ? null : newState;
    })
  }

  getRibbonViewportOverflowType() {
    return this.calculateRibbonViewportOverflowType(
      this.ribbon.current,
      this.ribbonViewport.current
    );
  }

  // returns RibbonViewportOverflowType
  calculateRibbonViewportOverflowType(content, container) {
    const containerMetrics = container.getBoundingClientRect();
    const containerMetricsRight = Math.round(containerMetrics.right);
    const containerMetricsLeft = Math.round(containerMetrics.left);

    const contentMetrics = content.getBoundingClientRect();
    const contentMetricsRight = Math.round(contentMetrics.right);
    const contentMetricsLeft = Math.round(contentMetrics.left);
    if (
      containerMetricsLeft > contentMetricsLeft &&
      containerMetricsRight < contentMetricsRight
    ) {
      return RibbonViewportOverflowType.BOTH;
    } 
    else if (contentMetricsLeft < containerMetricsLeft) {
      return RibbonViewportOverflowType.LEFT;
    } 
    else if (contentMetricsRight > containerMetricsRight) {
      return RibbonViewportOverflowType.RIGHT;
    } 
    else {
      return RibbonViewportOverflowType.NONE;
    }
  }

  onLeftButtonClickCallback = () => {
    // если мы уже в процессе обработки - пропускаем
    if (this.state.ribbonCSSTransitionLock) return;

    if (// If we have content overflowing both sides or on the left
        this.state.ribbonViewportOverflowType === RibbonViewportOverflowType.LEFT
        || this.state.ribbonViewportOverflowType === RibbonViewportOverflowType.BOTH
    ) {
      // Find how far this panel has been scrolled
      const availableScrollLeft = this.ribbonViewport.current.scrollLeft;

      // If the space available is less than two lots of our desired distance, just move the whole amount
      // otherwise, move by the amount in the settings
      const travelDistance = this.getOpportunisticClickScroll()
      const translateX = 
        (availableScrollLeft < travelDistance * 2)
          ? availableScrollLeft
          : travelDistance;

      this.setState({
        ribbonCSSTransitionLock: true,
        ribbonCSSTransitionDirection: RibbonCSSTransitionDirection.LEFT,
        ribbonCSStransitionTranslateXValue: translateX + 'px'
      })
    }
  }

  onRightButtonClickCallback = () => {
    // если мы уже в процессе обработки - пропускаем
    if (this.state.ribbonCSSTransitionLock) return;

    if (// If we have content overflowing both sides or on the right
        this.state.ribbonViewportOverflowType === RibbonViewportOverflowType.RIGHT
        || this.state.ribbonViewportOverflowType === RibbonViewportOverflowType.BOTH
    ) {
      // Get the right edge of the container and content
      const navBarRightEdge = this.ribbon.current.getBoundingClientRect().right;
      const navBarScrollerRightEdge = this.ribbonViewport.current.getBoundingClientRect().right;

      // Now we know how much space we have available to scroll
      const availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge);

      // If the space available is less than two lots of our desired distance, just move the whole amount
      // otherwise, move by the amount in the settings
      const travelDistance = this.getOpportunisticClickScroll()
      const translateX = 
        (availableScrollRight < travelDistance * 2)
          ? availableScrollRight
          : travelDistance;

      this.setState({
        ribbonCSSTransitionLock: true,
        ribbonCSSTransitionDirection: RibbonCSSTransitionDirection.RIGHT,
        ribbonCSStransitionTranslateXValue: (-translateX) + 'px',
      })
    }
  }

  // хитрый метод, срабатывает когда завершается transition элемента Contents
  // он должен считать результат транзишна (в пкс), записать его в стейт,
  // указать scrollLeft контейнеру, и сбросить транзишн (тоже через стейт)
  ribbonTransitionEndCallback = () => {
    // If in the middle of a move return
    if (!this.state.ribbonCSSTransitionLock) return;

    const styleOfTransform = window.getComputedStyle(this.ribbon.current, null)
    const transformMatrix = styleOfTransform.getPropertyValue("-webkit-transform") || styleOfTransform.getPropertyValue("transform");
    var transitionLength = Math.abs(parseInt(transformMatrix.split(",")[4]) || 0);

    const currentScroll = this.ribbonViewport.current.scrollLeft;
    const newScroll = Math.max(
      0,
      this.state.ribbonCSSTransitionDirection === RibbonCSSTransitionDirection.LEFT
        ? currentScroll - transitionLength
        : currentScroll + transitionLength
    );

    this.setState({
      ribbonCSSTransitionLock: false,
      ribbonCSStransitionTranslateXValue: null,
      ribbonViewportScrollLeft: newScroll
    });
  }

  render() {
    return (
      <div className="ribbon-wrapper">
        <nav
          className="ribbon-viewport dragscroll"
          ref={this.ribbonViewport}
          data-overflowing={this.state.ribbonViewportOverflowType}
          onScroll={this.ribbonViewportScrollCallback}
        >

          <div 
            ref={this.ribbon} 
            className={this.state.ribbonCSStransitionTranslateXValue? 'ribbon' : 'ribbon no-transition'}
            style={{
              transform: 
                this.state.ribbonCSStransitionTranslateXValue
                  ? `translateX(${this.state.ribbonCSStransitionTranslateXValue})`
                  : 'none'
            }} 
            onTransitionEnd={this.ribbonTransitionEndCallback}
          >
            <LinkList links={this.props.links} />
          </div>
        </nav>
           
        <button
          className="advancer left"
          type="button"
          onClick={this.onLeftButtonClickCallback}
        >
          <span className="svg">
            <RibbonArrowLeft />
          </span>
        </button>

        <button
          className="advancer right"
          type="button"
          onClick={this.onRightButtonClickCallback}
        >
          <span className="svg">
            <RibbonArrowRight />
          </span>
        </button>
      </div>
    );
  }
}

  export default Ribbon;
