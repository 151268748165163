import React from "react";


import config from "../../../config";

import { ReactComponent as CloserIcon } from '../../assets/closerIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/closeIcon.svg';

import './EventList.scss';
import { EventListStickyHeader } from "./StickyHeader";

import { Event } from "./Event";
import { Ad } from "./Ad";

const ListType = {
  MAPPED_AND_FEATURED: 0,

  // привязан к карте, т.е. показываем только те события которые на карте
  MAPPED: 1,

  // без привязки к карте, т.е. все события для выбранной карты
  COMPLETE: 2,

  CLUSTER: 3,
}

// props: events, hasClusteredEvents, clusteredEvents, onClusterClose, onLocationEscalate, date, scrollToEventId
class EventList extends React.Component {

  constructor(props) {
    super(props);

    // нужно чтобы
    // 1. определить ширину списка - ее нужно будет задать sticky-шапке этого списка явно
    // 2. верхнюю точку списка в момент когда нам надо подскроллить к конкретному элементу
    this.eventListRef = React.createRef();

    // нужно чтобы определить высоту шапки -
    // она динамическая (не использую абсолютные значения)
    // и эту высоту нужно указать как paddingTop собственно списку
    // когда страница начнет скроллиться
    this.stickyHeaderRef = React.createRef();

    this.state = {
      // режим когда список был включен на мобилке
      // (по умолчанию он выключен через css-классы)
      eventListRequestedOnMobile: false,

      // начался ли скролл? В этом случае нужно переключить
      // шапку списка в режим sticky
      pageScrolled: false,

      // тип списка
      type: ListType.MAPPED,

      // внутреннее состояние показывает что компонента стала следить за скроллом
      scrollTracked: false,

      // чтобы компонента не скроллила каждый раз когда прлисходит componentDidUpdate()
      scrolledTo: null,
    }
  }

  componentDidMount() {
    if (!config.isServer) {
      if (document.readyState === 'complete')
        this._install();
      else
        window.addEventListener('load', this._install)
    }
  }

  _install = () => {
    // навешиваем событие только после полной загрузки страницы
    // в противном случае, до того как загружен css,
    // ширина списка будет подсчитана неверно
    window.addEventListener('scroll', this.handleOnScroll);

    // указываем что мы теперь следим за скроллом
    this.setState({scrollTracked: true})

    // поскольку мы навесили событие сильно после componentDidMount(),
    // нужно вручную подсчитать скролл - вдруг страница уже подскроллена?
    this.handleOnScroll()
  }

  componentWillUnmount = () => {
    if (!config.isServer) {
      window.removeEventListener('scroll', this.handleOnScroll)

      this.setState({scrollTracked: false})
    }
  }

  static getDerivedStateFromProps(props, state) {
    // нужно сбросить state.type = CLUSTER т.к. его некому сбросить внутри компоненты
    if (
        state.type === ListType.CLUSTER
        && (props.scrollToEventId === null || props.scrollToEventId.length === 1)
    )
      return { type: ListType.MAPPED }

    return null;
  }

  componentDidUpdate(prevProps) {
    // Cluster list
    // Если пришел запрос на кластер - надо сперва активировать список (если скрыт)
    if (
        this.props.hasClusteredEvents === true &&
        this.props.hasClusteredEvents !== prevProps.hasClusteredEvents
    )
      if (this._isEventListHidden())
        this.activateList()

    // нужно подскроллить список наверх если пришли изменения: изменился год/месяц/наличие кластера
    if (
        !config.isServer &&
        (
          prevProps.date.month !== this.props.date.month ||
          prevProps.date.year !== this.props.date.year ||
          prevProps.hasClusteredEvents !== this.props.hasClusteredEvents
        )
    )
      window.scrollTo(0, 0);

    //
    // на удаление
    //

    // Делаем подскролл к элементу если нас об этом просят
    if(
        this.props.scrollToEventId &&

        // обязательно после полной загрузки страницы, потому что только после нее
        // мы следим за скроллом (handleScroll) и правильно рассчитываем размеры элементов
        // Что если подсролл произойдет до? Да ничего страшного, после навешивания handleScroll
        // мы изменим state и зайдем сюда (componentDidUpdate) снова, поэтому подскролл
        // случится рано или поздно
        this.state.scrollTracked
    ) {

      // список может быть скрытым - это поведение дефолтное на мобильных
      // при чем достигается средствами css (классы .d-none .d-md-block)
      // поэтому мы нигде не храним состояние. Его нужно определиьт
      if (
          // если скрыто
          !this.state.eventListRequestedOnMobile &&

          // и действительно не видно (display: none)
          window.getComputedStyle(this.eventListRef.current).display === 'none'

          && this.props.scrollToEventId !== this.state.scrolledTo
      ) {
        // то сперва активируем список
        this.activateList(ListType.MAPPED);

        // и выходим - ведь сначала нужно дождаться render() и только после этого
        // делать подскролл. Состояние мы не потеряем т.к. при render() этого компонента
        // родитель не перетрет нам пропсу scrollToEventId - она останется у нас
        // и мы считаем его при очередном вызове componentDidUpdate() который непременно
        // случится как только список отобразится
        return;
      }

      // если подскролл к одному элементу
      if (this.props.scrollToEventId.length === 1) {

        // работы на стороне клиента
        if (!config.isServer) {
          const eventId = this.props.scrollToEventId[0];

          if (this.state.scrolledTo !== this.props.scrollToEventId) {

            //
            // начинаем подскролл
            //

            // определяем точку элемента
            const itemOffsetY = this.listItemRefs[eventId].current.offsetTop;

            // до куда скроллить? явно не до верхней границы, лучше всего подскроллить
            // под шапку списка. Как ее найти? Можно сложить высоту шапки страницы и высоту
            // шапки списка, но там много динамики, поэтому проще взять физические координаты
            // щапки списка прямо сейчасс
            const stickyHeaderHeight = this.stickyHeaderRef.current.getBoundingClientRect().height;

            // верхняя граница контенера
            const eventlistOffsetY = this.eventListRef.current.offsetTop;

            // подскролл!
            const desiredY = itemOffsetY - eventlistOffsetY - stickyHeaderHeight;
            window.scrollTo(0, desiredY);

            this.setState({scrolledTo: this.props.scrollToEventId})
          }
        }
      }

      // если подскролл к нескольким элементам
      else if (
          this.props.scrollToEventId.length > 1 &&
          this.state.scrolledTo !== this.props.scrollToEventId &&
          this.state.type !== ListType.CLUSTER
      )
        this.setState({
          type: ListType.CLUSTER,
          scrolledTo: this.props.scrollToEventId,
        })
    }
  }

  handleOnScroll = () => {
    var pageScrolled = window.scrollY > 0;
    if (
        this.state.pageScrolled !== pageScrolled
      )
      this.setState({ pageScrolled });
  }

  _isEventListHidden() {
    if (config.isServer)
      throw new Error("EventList._isEventListVisible() is not expected to be called at Server side");

    if (typeof this.eventListRef.current !== 'object')
      throw new Error("EventList._isEventListVisible(): eventListRef is not yet ready, how it was called?");

    return window.getComputedStyle(this.eventListRef.current).display === 'none'
  }

  // mobile only
  // Нажатие на кнопку раскрытия списка (который скрыт по умолчанию на мобилке)
  activateList = (type) => {
    if (this.state.eventListRequestedOnMobile !== true)
      this.setState({
        eventListRequestedOnMobile: true,
        type
      });
  }

  // mobile only
  // Нажатие на кнопку сокрытия списка (который на мобилке скрыт по умолчанию
  // и должен быть активирован явно пользователем)
  deactivateList = () => {
    if (this.props.onClusteredEventListClose)
      this.props.onClusteredEventListClose()

    if (this.state.eventListRequestedOnMobile === true)
      this.setState({eventListRequestedOnMobile: false})
  }

  // mobile only
  // кнопка для раскрытия списка (который на мобилке скрыт по умолчанию
  // и должен быть активирован явно пользователем)
  renderStickyListOpener() {
    // не показываем если распахнут список
    if (this.state.eventListRequestedOnMobile)
      return;

    return (
      <div className='event-list-sticky-header fixed-bottom d-md-none mx-2'>
        <EventListStickyHeader
            listTypes={ListType}
            events={(_type) => this.getFilteredEvents(_type)}
            onClick={this.activateList}
            date={this.props.date}
          />
      </div>
    );
  }

  switchListType = (type) => {
    this.setState({type})
  }

  getFilteredEvents(listType) {
    if (!listType)  {
      listType = this.state.type;

      if (listType === ListType.MAPPED)
        listType = ListType.MAPPED_AND_FEATURED;
    }

    switch (listType) {
      case ListType.MAPPED_AND_FEATURED: return this.props.events.filter(event => event.onMap || event.isf);
      case ListType.MAPPED: return this.props.events.filter(event => event.onMap);
      case ListType.COMPLETE: return this.props.events;
      case ListType.CLUSTER: return this.props.events.filter(event => this.props.scrollToEventId.includes(event.id))
      default: return [];
    }
  }

  renderStickyListHeader() {
    return (
      <div
          ref={this.stickyHeaderRef}
          // можно было бы добавить:
          // + (this.state.pageScrolled ? ' position-fixed' : '')
          // но это можно сделать средствами css - по идее будет быстрее
          // Имплементация: .scrolled .event-list-sticky-header {}
          className="event-list-sticky-header"
          style={{
            width:
              this.state.pageScrolled
                ? this.eventListRef.current.getBoundingClientRect().width + 'px'
                : '100%',
          }}
        >
          <EventListStickyHeader
            eventListType={this.state.type}
            listTypes={ListType}
            events={(_type) => this.getFilteredEvents(_type)}
            onClick={this.switchListType}
            date={this.props.date}
          />
        </div>
    )
  }

  renderStickyListCloser() {
    if (this.state.eventListRequestedOnMobile)
      return (
        <div
            className="fixed-bottom event-list-closer d-flex align-items-center justify-content-center"
            onClick={this.deactivateList}
        >
          <span className="svg">
            <CloserIcon />
          </span>
        </div>
      )
  }

  onLocationEscalateCallback = (event) => {
    this.deactivateList();
    this.props.onLocationEscalate({ center: event.coords })
  }

  _shouldHaveStickyHeader() {
    return this.state.pageScrolled && !this.props.hasClusteredEvents
  }

  _renderList({events, styles, selectedEventId}) {
    events = [...events];

    const hasFi = (start, end) => 0 !== events
      .slice(start, end)
      .reduce((a, b) => a + (b.fi? 1 : 0), 0);

    if (!hasFi(0, 4))
      events.splice(2, 0, { ad: true });

    if (selectedEventId) {
      let id = events.findIndex(v => v.id === selectedEventId)
      if (id !== -1 && !hasFi(id, id + 4)) {
        events.splice(id + 3, 0, { ad: true })
      }
    }

    return (
      <ul
          className="list-unstyled m-0" style={styles || {}}
      >{events.map((event, idx) => {
          if (event.ad) return <Ad sequentialId={idx}/>;
          this.listItemRefs[event.id] = React.createRef()
          return <Event onClick={() => this.onLocationEscalateCallback(event)} event={event} sequentialId={idx} ref={this.listItemRefs[event.id]} selectedEventId={selectedEventId} />;
        })}
      </ul>
    )
  }

  renderList() {
    this.listItemRefs = {};

    const selected =
      (this.props.scrollToEventId && this.props.scrollToEventId.length === 1)
        ? this.props.scrollToEventId[0]
        : null;

    const paddingTop =
        this._shouldHaveStickyHeader()
                ? this.stickyHeaderRef.current.getBoundingClientRect().height + 'px'
                : 0;

    return this._renderList({
              events: this.getFilteredEvents(),
              styles: {paddingTop},
              selectedEventId: selected,
    })
  }

  // отрисовка списка событий из кластера
  renderClusteredList() {
    if (!this.props.hasClusteredEvents) return

    return (<>
      <div className="shadow clustered-list">
        <div className="row no-gutters p-3">
          <div className="col overflow-hidden flex-nowrap display-table-cell">
            {this.props.clusteredEvents.length} events selected
          </div>
          <div className="col col-auto">
            <button
              className="btn btn-sm p-0"
              onClick={this.deactivateList}
            >
              <span class="svg">
                <CloseIcon />
              </span>
            </button>
          </div>
        </div>
        <div class="row no-gutters">
          {this._renderList({ events: this.props.clusteredEvents })}
        </div>
      </div>
      <div className="clustered-list-overlay w-100 h-100 position-absolute">
      </div>
    </>)
  }

  renderFooter() {
    return (
      <footer className="row no-gutters p-0">
        <div className="col p-3">
            <p className="small">Tango gives us a unique opportunity to travel the world only speaking a universal language of dance. Here, on Tangocat, we want to share our passion to the dance and help you to explore a vast number of tango festivals and marathons worldwide.</p>
            <p className="small">When you look for a place for your next tango holiday here on the map, you can see all possible tango events for your destination. Every event is linked to a place to make your journey easier and more fun!</p>
            <p className="small mb-0 copy">Tangocat.net &copy; 2019</p>
        </div>
      </footer>
    )
  }

  render() {
    return (<>
      {this.renderStickyListOpener()}

      <div
          ref={this.eventListRef}
          className={
            'event-list' +
            (this._shouldHaveStickyHeader() ? ' scrolled' : '') +
            (this.state.eventListRequestedOnMobile ? ' mobile' : ' d-none d-md-block')
          }
      >
        {this.renderClusteredList()}

        {this.renderStickyListHeader()}

        {this.renderList()}

        {this.renderStickyListCloser()}

        {this.renderFooter()}
      </div>
    </>);
  }
}

export default EventList