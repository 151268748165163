import React from "react";
import Ribbon from "../Ribbon/Ribbon";
import I18n from "../../../modules/i18n";

class StickyNavStripe extends React.Component {
  getNavigationLinks() {
    var res = [];

    res.push({
      to: '/',
      label: 'UPCOMING',
      active: !this.props.selected.year,
    })

    var d = new Date();

    // нужно обязательно установить дату в начало
    // потому что 31 числа каждого месяца не будет отображаться
    // следующий месяц. Пример: 31 июля 2019 + 2 мес = 1 октября
    // (а не 31 сентября)
    d.setDate(1);

    var previousYear = null;

    for (var i = 0; i < 12; i++) {
      let year = d.getFullYear()
      if (previousYear !== year) {
        res.push({
          to: `/${year}`,
          label: year,
          active: !this.props.selected.month && this.props.selected.year === year,
        });
        previousYear = year;
      }
      res.push({
        to: `/${year}/${d.getMonth() + 1}`,
        label: I18n.getShortMonthNameByDate(d),
        active: this.props.selected.month === (d.getMonth() + 1) && this.props.selected.year === year,
      });
      d.setMonth(d.getMonth() + 1);
    }
    return res
  }

  render() {
    return (
      <div className="fixed-top tc-stickynavstripe-container" role="navigation">
        <div className="container-fluid">
          <div className="row tc-row-stickynavstripe">
            <div className="col-md-8 mt-3">
              <Ribbon links={this.getNavigationLinks()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StickyNavStripe