import React from 'react';
import { Modal as BootstrapModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { ReactComponent as CloseIcon } from '../assets/closeIcon.svg';

import './Modal.scss';

// isOpen, onToggle, title, footer, children
class Modal extends React.Component {

    onClose = () => {
        this.props.onToggle();
    }

    render() {
        const closeBtn = <button className="btn btn-sm p-0" onClick={this.onClose}><span class="svg"><CloseIcon /></span></button>;

        return (
            <BootstrapModal isOpen={this.props.isOpen} toggle={this.props.onToggle} className="tc-modal">
                <ModalHeader toggle={this.props.onToggle} close={closeBtn}>{this.props.title}</ModalHeader>
                <ModalBody>
                    {this.props.children}
                </ModalBody>
                {this.props.footer &&
                    <ModalFooter>{this.props.footer}</ModalFooter>
                }
            </BootstrapModal>
        );
    }
}

export default Modal;