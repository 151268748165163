import React from 'react';
import Page from '../components/Page';

import SubscribeForm from '../components/Forms/SubscribeForm';

export default () => (
  <Page
    id="page"
    title="Newsletter"
  >
    <div className="container mt-5">
      <div className="row pt-5 d-flex justify-content-center">
        <div className="col-sm-10 col-md-6">
          <p>
            Tangocat is constantly evolving: we not only update our database, but we also add new features. You can leave you email to keep updated about them.
          </p>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-10 col-md-6 text-center">
          <SubscribeForm/>
        </div>
      </div>
    </div>
  </Page>
);

