
const schema = {
    events: {
        latest: () => '/api/events/latest',
        specific: (year, month) => `/api/events/${year}/${month}`
    }
}

// const u = (proto, host, path) => `${proto}://${host}/${path}`

// const _process = (proto, host) => {
//     const retval = {}
//     Object.keys(schema).map(k => {
//         retval[k] = {}
//         return Object.keys(schema[k]).map(k2 => {
//             retval[k][k2] = () => u(proto, host, schema[k][k2].apply(arguments))
//         })
//     })
//     return retval
// }

export default schema