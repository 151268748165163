import React from "react";
import { Link } from "react-router-dom";

const links = [
  {
    to: "/newsletter",
    label: "Newsletter"
  },
  {
    to: "/analytics",
    label: "Analytics",
  },
  {
    to: "/about",
    label: "About"
  },
];

const isCurrent = (to, current) => {
  return to === current;

  // if (to === "/" && current === to) {
  //   return true;
  // } else if (to !== "/" && current.includes(to)) {
  //   return true;
  // }

  // return false;
};

const HeaderLink = ({ to, label, disabled, current, onClick }) => {
  const className = 'nav-item mr-3' +
    (isCurrent(to, current) ? " active": '' ) +
    (disabled ? ' disabled' : '')
  return (
    <li className={className}>
      <Link className="nav-link" to={to} onClick={onClick}>
        {label}
      </Link>
    </li>
  )
}

export default ({ current, className, onClick }) => (
  <ul className={className}>
    {links.map((link, index) => {
      return <HeaderLink key={index} current={current} {...link} onClick={onClick} />;
    })}
  </ul>
);
