import React from 'react';
import Page from '../components/Page';

export default () => (
  <Page
    id="page"
    title="Page Not Found"
    noCrawl
  >
    <div class="container mt-5">
      <div className="row pt-5">
        <div class="col text-center">
          <p class="h3">
            Ooops... this page does not exist :(
          </p>
        </div>
      </div>
      
      <div className="row mt-5 d-flex justify-content-center">
        <div class="col text-center">
          <p>
            Drop us a line if you think this is a mistake: <a href="mailto:hello@tangocat.net">hello@tangocat.net</a>
          </p>
        </div>
      </div>
    </div>
  </Page>
);

