/**
 * 
 * @param {Date} date 
 */
export const displayDate = date => {
    const [y, m, d] = [date.getFullYear(), date.toLocaleString('en-US', { month: 'long' }), date.getDate()]
    if (new Date().getFullYear() === y)
        return `${m} ${d}`
    else return `${m} ${d}, ${y}`
}

/**
 * 
 * @param {Date} date1 
 * @param {Date} date2 
 */
export const displayDateRange = (date1, date2) => {
    if (date1.toLocaleDateString() === date2.toLocaleDateString())
        return displayDate(date1);

    const [
        year,
        y1, m1, d1,
        y2, m2, d2,
    ] = [
        new Date().getFullYear(),
        date1.getFullYear(), date1.toLocaleString('en-US', { month: 'long' }), date1.getDate(),
        date2.getFullYear(), date2.toLocaleString('en-US', { month: 'long' }), date2.getDate()
    ];

    // see https://en.wikipedia.org/wiki/Wikipedia:Manual_of_Style/Dates_and_numbers#Ranges
    // const nbsp = '&nbsp;'
    // const ndash = '&ndash;'
    // const snb = `${nbsp}${ndash}${nbsp}`

    if (y1 === y2) {
        const _optYear = year === y1? '' : `,&nbsp;${y1}`;

        if (m1 === m2) {
            // return "".concat(m1, nbsp, d1, ndash, d2, _optYear)
            return `${m1}&nbsp;${d1}&ndash;${d2}${_optYear}`
        }
        else {
            return `${m1}&nbsp;${d1}&nbsp;&ndash;&nbsp;${m2}&nbsp;${d2}${_optYear}`
        }
    }
    else {
        const _optYear1 = year === y1? '' : `,&nbsp;${y1}`;
        const _optYear2 = year === y2? '' : `,&nbsp;${y2}`;

        return `${m1} ${d1}${_optYear1} - ${m2} ${d2} ${_optYear2}`
    }
}