import React from "react";
import { Element } from "./Element";

import image1 from "./assets/a1.png";
import image2 from "./assets/a2.png";
import image3 from "./assets/a3.png";
import image4 from "./assets/a4.png";
import './Ad.scss';

const images = [
    image1, image2, image3, image4
]

function getRandomImage() {
    return images[Math.floor(Math.random() * (images.length))]
}

const PICKED_IMAGE = getRandomImage();


function Ad({ sequentialId }) {
    return <Element id={sequentialId}>
        <img src={PICKED_IMAGE} alt="Ad" className="ad__im" />
    </Element>
}

export {
    Ad
}